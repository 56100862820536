import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  faCaretLeft,
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faAngleLeft,
  faAngleRight,
  faArrowRightLong,
  faArrowLeftLong,
  faArrowRight,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';

/**
 * Component to display an arrow image/icon/svg
 */
//TODO proposal barbeitoalex - change all arrow to svg/icons to be able to apply styles easily
@Component({
  selector: 'ush-arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowComponent {
  /**
   * @docsNotRequired
   */
  faCaretRight = faCaretRight;

  /**
   * @docsNotRequired
   */
  faCaretLeft = faCaretLeft;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faLongArrowLeft = faArrowLeftLong;
  faLongArrowRight = faArrowRightLong;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  /**
   * Number to know what template arrow select:
   *
   * 1. Image simple
   *
   * 2. Image long arrow
   *
   * 3. Icon chevron
   *
   * 4. Icon caret
   *
   * 5. Icon long arrow
   *
   * 6. Icon long arrow
   *
   * 7. ?
   *
   * 8. SVG caet arrow
   */
  @Input()
  template: number | undefined = 5;

  /**
   * Arrow orizontal orientation
   */
  //TODO proposal barbeitoalex - add top and bottom
  @Input()
  orientation: 'left' | 'right' = 'left';
}
